import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AnalysisForNumber from './AnalysisForNumber';
import AnalysisForSportNumber from './AnalysisForSportNumber';
import Admin from './Admin';
import ErrorBoundary from './ErrorBoundary';
import 'antd/dist/reset.css';

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/analysis/:number" element={<AnalysisForNumber />} />
            <Route path="/sport-analysis/:number" element={<AnalysisForSportNumber />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;