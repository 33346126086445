export const calculateMA = (data, days) => {
    const result = [];
    let sum = 0;
    for (let i = data.length - 1; i >= 0; i--) {
      sum += data[i].endValue;
      if (i <= data.length - days) {
        result.unshift(sum / days);
        sum -= data[i + days - 1].endValue;
      } else {
        result.unshift(null);
      }
    }
    return result;
  };
  
  export const calculateMACD = (data, fastPeriod = 12, slowPeriod = 26, signalPeriod = 9) => {
    const calculateEMA = (data, period) => {
      const k = 2 / (period + 1);
      let ema = data.slice(0, period).reduce((sum, value) => sum + value.endValue, 0) / period;
      return data.map((value, i) => {
        ema = i < period ? ema : value.endValue * k + ema * (1 - k);
        return ema;
      });
    };
  
    const fastEMA = calculateEMA(data, fastPeriod);
    const slowEMA = calculateEMA(data, slowPeriod);
    const macd = fastEMA.map((fast, i) => fast - slowEMA[i]);
    
    const signal = calculateEMA(macd.map(value => ({ endValue: value })), signalPeriod);
    const histogram = macd.map((macdValue, i) => macdValue - signal[i]);
  
    const validIndex = Math.max(fastPeriod, slowPeriod, fastPeriod + slowPeriod - 2 + signalPeriod);
    return {
      macd: macd.slice(validIndex),
      signal: signal.slice(validIndex),
      histogram: histogram.slice(validIndex)
    };
  };