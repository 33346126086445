import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import qrCodeImage from './images/dianbao.png';  // 导入二维码图片

// 新增的QR码模态框组件
const QRCodeModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '10px',
        maxWidth: '90%',
        maxHeight: '90%',
        overflow: 'auto',
        position: 'relative'
      }}>
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            border: 'none',
            background: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer'
          }}
        >
          &times;
        </button>
        <h2>电报Telegram</h2>
        {/* 这里放置您的二维码图片 */}
        <img src={qrCodeImage} alt="联系收米鸭二维码" style={{ width: '290px', height: '382px' }} />
      </div>
    </div>
  );
};

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const buttonStyle = {
    padding: '1rem 2rem',
    fontSize: '1rem',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70px', // 增加按钮高度
    width: '100%',
    maxWidth: '300px',
    textDecoration: 'none',
    marginBottom: '1rem',
  };

  const buttonTextStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%', // 使文字容器填满按钮高度
  };

  const buttonLineStyle = {
    marginBottom: '0.5rem', // 为第一行文字添加底部边距
  };

  const linkStyle = {
    textDecoration: 'none',
    width: '100%',
    maxWidth: '300px',
    display: 'block',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
    padding: '0.5rem',
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
  };

  return (
    <div style={containerStyle}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginBottom: '2rem',
        textAlign: 'center',
      }}>
        <h1 style={{ 
          margin: 0, 
          textAlign: 'center',
          fontSize: 'calc(1.5rem + 1vw)',
          color: '#EA4335'
        }}>
        【收米鸭】彩票胆码分析系统
        </h1>
        <h2 style={{ 
          margin: 0, 
          textAlign: 'center',
          marginTop: '1.5rem',
          fontSize: 'calc(0.9rem + 0.4vw)'
        }}>
        将股票"波浪理论"引入博彩实战，打造"定胆神器"。
        </h2>
      </div>
      <div style={buttonContainerStyle}>
        <Link to="/analysis/0" style={linkStyle}>
          <button style={{...buttonStyle, backgroundColor: '#34A853', color: 'white'}}>
            <div style={buttonTextStyle}>
              <span style={buttonLineStyle}>中国福利彩票 3D玩法</span>
              <span>►►定胆神器◄◄</span>
            </div>
          </button>
        </Link>
        <Link to="/sport-analysis/0" style={linkStyle}>
          <button style={{...buttonStyle, backgroundColor: '#4285F4', color: 'white'}}>
            <div style={buttonTextStyle}>
              <span style={buttonLineStyle}>中国体育彩票 排列3玩法</span>
              <span>►►定胆神器◄◄</span>
            </div>
          </button>
        </Link>
        <a href="https://blog.shoumiya.com" target="_blank" rel="noopener noreferrer" style={linkStyle}>
          <button style={{...buttonStyle, backgroundColor: '#EA4335', color: 'white'}}>
            <div style={buttonTextStyle}>
              <span style={buttonLineStyle}>定胆神器 收米宝典</span>
              <span>►►收米鸭博客◄◄</span>
            </div>
          </button>
        </a>
        {/* 修改最后一个按钮，使其打开模态框而不是链接到外部网站 */}
        <button 
          onClick={() => setIsModalOpen(true)} 
          style={{...buttonStyle, backgroundColor: '#FBBC05', color: 'black'}}
        >
          <div style={buttonTextStyle}>
            <span style={buttonLineStyle}>全球彩票 胆码工具开发</span>
            <span>►►商务合作◄◄</span>
          </div>
        </button>
      </div>

      {/* 添加QR码模态框组件 */}
      <QRCodeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default HomePage;