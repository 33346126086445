import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Spin, Modal, Select } from 'antd';
import ErrorBoundary from './ErrorBoundary';
import { calculateMA, calculateMACD } from './utils/calculations';
import { ToggleButton } from './components/ChartControls';
import { getChartOptions, getChartData } from './config/chartConfig';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, BarController, LineController } from 'chart.js';
import 'chartjs-plugin-crosshair';
import { debounce } from 'lodash';
import qrCodeImage from './images/qrcode.png'; // 新增这行
import html2canvas from 'html2canvas';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, BarController, LineController);

const { Option } = Select;

function AnalysisForSportNumber() {
  const { number = '0' } = useParams();
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [displayRange, setDisplayRange] = useState(100);
  const [startIndex, setStartIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [showKLine, setShowKLine] = useState(false);
  const [showKBar, setShowKBar] = useState(true);
  const [showMA5, setShowMA5] = useState(true);
  const [showMA10, setShowMA10] = useState(true);
  const [showMA20, setShowMA20] = useState(true);
  const [showMACD, setShowMACD] = useState(false);
  const [showMACDFast, setShowMACDFast] = useState(false);
  const [showMACDSlow, setShowMACDSlow] = useState(false);
  const [globalMacdData, setGlobalMacdData] = useState({ macd: [], signal: [], histogram: [] });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const [activePeriod, setActivePeriod] = useState('100期');
  const [activeNumber, setActiveNumber] = useState(number);
  const [isLoading, setIsLoading] = useState(false);
  const chartRef = useRef(null);
  const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState(false); // 新增这行

  const fetchData = useCallback(() => {
    console.log('Fetching sport lottery data...');
    setIsLoading(true);
    axios.get('/api/sport-lottery')
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data.length === 0) {
          console.log('No data received from API');
          return;
        }
        let kValue = 0;
        const processedData = response.data
          .sort((a, b) => a.drawNumber - b.drawNumber)
          .map(item => {
            const hasNumber = item.number.includes(activeNumber.toString());
            const startValue = kValue;
            kValue = hasNumber ? startValue + 0.729 : startValue - 0.271;
            return {
              ...item,
              hasNumber,
              startValue,
              endValue: kValue
            };
          })
          .reverse();
        console.log('Processed data:', processedData);
        setAllData(processedData);
        
        const macdResult = calculateMACD(processedData);
        setGlobalMacdData(macdResult);
        
        setStartIndex(0);
      })
      .catch(error => console.error('Error fetching sport lottery data:', error))
      .finally(() => setIsLoading(false));
  }, [activeNumber]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (allData.length > 0) {
      const defaultDisplayRange = Math.min(100, allData.length);
      setStartIndex(0);
      setDisplayRange(defaultDisplayRange);
      setActivePeriod('100期');
    }
    setActiveNumber(number);
  }, [allData, number]);

  useEffect(() => {
    document.body.setAttribute('data-print-date', new Date().toLocaleDateString());
  }, []);

  const visibleData = useMemo(() => 
    allData.slice(startIndex, startIndex + displayRange), 
    [allData, startIndex, displayRange]
  );

  const handlePeriodChange = (value) => {
    setStartIndex(0);
    if (value === '全部') {
      setDisplayRange(allData.length);
      setActivePeriod('全部');
    } else {
      const numericValue = parseInt(value);
      setDisplayRange(numericValue);
      setActivePeriod(value);
    }
  };

  const handleNumberChange = (value) => {
    setActiveNumber(value);
    navigate(`/sport-analysis/${value}`);
    setDisplayRange(100);
    setActivePeriod('100期');
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStart(event.clientX);
  };
  
  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const dragDistance = event.clientX - dragStart;
    const sensitivity = 10;
    const newStartIndex = Math.min(
      Math.max(0, startIndex + Math.round(dragDistance / sensitivity)),
      allData.length - displayRange
    );
    setStartIndex(newStartIndex);
    setDragStart(event.clientX);
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = useMemo(
    () =>
      debounce((e) => {
        if (e.deltaY < 0) {
          setDisplayRange((prev) => Math.max(50, prev - 50));
        } else {
          setDisplayRange((prev) => Math.min(2000, prev + 100));
        }
      }, 100),
    []
  );

  const ma5 = useMemo(() => calculateMA(visibleData, 5), [visibleData]);
  const ma10 = useMemo(() => calculateMA(visibleData, 10), [visibleData]);
  const ma20 = useMemo(() => calculateMA(visibleData, 20), [visibleData]);
 
  const visibleMacdData = useMemo(() => ({
    macd: globalMacdData.macd.slice(startIndex, startIndex + displayRange),
    signal: globalMacdData.signal.slice(startIndex, startIndex + displayRange),
    histogram: globalMacdData.histogram.slice(startIndex, startIndex + displayRange)
  }), [globalMacdData, startIndex, displayRange]);

  const chartData = useMemo(() => {
    if (visibleData.length === 0) {
      return { labels: [], datasets: [] };
    }
    return getChartData(
      visibleData, 
      showKLine, 
      showKBar, 
      showMA5, 
      showMA10, 
      showMA20, 
      showMACD, 
      showMACDFast, 
      showMACDSlow, 
      ma5, 
      ma10, 
      ma20, 
      visibleMacdData
    );
  }, [visibleData, showKLine, showKBar, showMA5, showMA10, showMA20, showMACD, showMACDFast, showMACDSlow, ma5, ma10, ma20, visibleMacdData]);

  const options = useMemo(() => getChartOptions(visibleData, visibleMacdData), [visibleData, visibleMacdData]);

  const handleSaveImage = () => {
    const element = document.body;

    // 临时隐藏可能导致问题的元素
    const noscriptElements = document.getElementsByTagName('noscript');
    Array.from(noscriptElements).forEach(el => el.style.display = 'none');

    html2canvas(element, {
      scale: 2,
      useCORS: true,
      onclone: (clonedDoc) => {
        clonedDoc.body.style.padding = '20px';
        Array.from(clonedDoc.getElementsByClassName('no-print')).forEach(el => el.remove());
      }
    }).then(canvas => {
      const link = document.createElement('a');
      link.download = 'chart.png';
      link.href = canvas.toDataURL();
      link.click();

      // 恢复隐藏的元素
      Array.from(noscriptElements).forEach(el => el.style.display = '');
    });
  };

  // 新增这两个函数
  const handleLatestDrawClick = () => {
    setIsQRCodeModalVisible(true);
  };

  const handleQRCodeModalClose = () => {
    setIsQRCodeModalVisible(false);
  };

  return (
    <ErrorBoundary>
      <div className="App" style={{ backgroundColor: '#f0f0f0', padding: '20px', maxWidth: '100%', overflowX: 'hidden' }} onWheel={handleWheel}>
        <Link to="/" className="no-print" style={{ position: 'absolute', top: '10px', left: '10px', textDecoration: 'none' }}>
          <button style={{ 
            padding: '5px 10px', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            color: 'black'
          }}>
            <span>返回</span>
            <span>主页</span>
          </button>
        </Link>

        <button 
          className="no-print" 
          onClick={handleSaveImage}
          style={{ 
            position: 'absolute', 
            top: '10px', 
            right: '10px',
            padding: '5px 10px', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          <span>保存</span>
          <span>图片</span>
        </button>

        <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: 'calc(1.1rem + 0.7vw)' }}>
          【体彩P3】
          <span style={{ fontWeight: 'bold', color: 'red' }}>
            胆码{activeNumber}
          </span>
          {' '}走势
        </h1>
        
        <div className="no-print" style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
          <Select 
            value={activeNumber}
            style={{ width: 120, marginRight: '10px' }} 
            onChange={handleNumberChange}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
              <Option key={num} value={num.toString()}>{`胆码 ${num}`}</Option>
            ))}
          </Select>

          <Select 
            value={activePeriod}
            style={{ width: 120 }} 
            onChange={handlePeriodChange}
          >
            <Option value="100期">100期</Option>
            <Option value="500期">500期</Option>
            <Option value="1000期">1000期</Option>
            <Option value="全部">全部</Option>
          </Select>
        </div>

        <div className="no-print" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '10px' }}>
            <ToggleButton isActive={showKBar} onClick={() => setShowKBar(!showKBar)}>波浪图</ToggleButton>
            <ToggleButton isActive={showMA5} onClick={() => setShowMA5(!showMA5)}>5日线</ToggleButton>
            <ToggleButton isActive={showMA10} onClick={() => setShowMA10(!showMA10)}>10日线</ToggleButton>
            <ToggleButton isActive={showMA20} onClick={() => setShowMA20(!showMA20)}>20日线</ToggleButton>
            <ToggleButton isActive={showMACD} onClick={() => setShowMACD(!showMACD)}>MACD图</ToggleButton>
            <ToggleButton isActive={showMACDFast} onClick={() => setShowMACDFast(!showMACDFast)}>MA快线</ToggleButton>
            <ToggleButton isActive={showMACDSlow} onClick={() => setShowMACDSlow(!showMACDSlow)}>MA慢线</ToggleButton>
            {/* <ToggleButton isActive={showKLine} onClick={() => setShowKLine(!showKLine)}>K线</ToggleButton> */}
          </div>
        </div>

        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 150px)' }}>
            <Spin size="large" tip="Loading data..." />
          </div>
        ) : visibleData.length > 0 ? (
          <div 
            className="chart-container"
            ref={chartRef}
            style={{ 
              backgroundColor: 'white', 
              padding: '10px', //减少内边距
              borderRadius: '5px', //减小圆角
              height: 'calc(100vh - 90px)',//增加高度
              width: '100%',
              overflow: 'hidden',
              position: 'relative'
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div 
              className="latest-draw" 
              style={{
                position: 'absolute',
                top: '5px',
                left: '3%',
                zIndex: 10,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                padding: '5px 10px',
                borderRadius: '5px',
                fontWeight: 'bold',
                color: 'red',
                cursor: 'pointer'
              }}
              onClick={handleLatestDrawClick}
            >
              {visibleData[0] && `${visibleData[0].drawNumber}期【${visibleData[0].number}】►本期买点指南◄`}
            </div>
            <ReactChart 
              type='bar' 
              data={chartData} 
              options={options} 
              redraw={false}
              plugins={[{
                id: 'customCrosshair',
                afterDraw: (chart) => {
                  if (chart.tooltip._active && chart.tooltip._active.length) {
                    const activePoint = chart.tooltip._active[0];
                    const { ctx } = chart;
                    const { x } = activePoint.element;
                    const topY = chart.scales.y.top;
                    const bottomY = chart.scales.y.bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#FF0000';
                    ctx.setLineDash([5, 5]);
                    ctx.stroke();
                    ctx.restore();
                  }
                }
              }]}
            />
          </div>
        ) : (
          <p>No data available...</p>
        )}

        <Modal
          title="wechat微信号:dafudagui49"
          visible={isQRCodeModalVisible}
          onCancel={handleQRCodeModalClose}
          footer={null}
        >
          <img src={qrCodeImage} alt="WeChat QR Code" style={{ width: '100%' }} />
        </Modal>
      </div>
    </ErrorBoundary>
  );
}

export default AnalysisForSportNumber;