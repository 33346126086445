import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Pagination, Input, Modal, message, Form, Button, Select } from 'antd';
import 'antd/dist/reset.css';

const { Option } = Select;

function Admin() {
  const [number, setNumber] = useState('');
  const [drawNumber, setDrawNumber] = useState('');
  const [lotteryData, setLotteryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [searchDrawNumber, setSearchDrawNumber] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [deleteDrawNumber, setDeleteDrawNumber] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [lotteryType, setLotteryType] = useState('welfare'); // 'welfare' or 'sport'

  const fetchLotteryData = useCallback(() => {
    console.log('Fetching lottery data...');
    const endpoint = lotteryType === 'welfare' ? '/api/lottery' : '/api/sport-lottery';
    axios.get(endpoint)
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data.length === 0) {
          console.log('No data received from API');
          return;
        }
        setLotteryData(response.data);
        if (response.data.length > 0) {
          const latestDrawNumber = Math.max(...response.data.map(item => parseInt(item.drawNumber)));
          setDrawNumber((latestDrawNumber + 1).toString().padStart(7, '0'));
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [lotteryType]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchLotteryData();
    }
  }, [isLoggedIn, lotteryType, fetchLotteryData]);

  const showAddConfirm = (e) => {
    e.preventDefault();
    setIsAddModalVisible(true);
  };

  const handleAddConfirm = async () => {
    const endpoint = lotteryType === 'welfare' ? '/api/admin/add' : '/api/sport-lottery';
    try {
      const response = await axios.post(endpoint, { drawNumber, number, password });
      if (response.data.success) {
        console.log('Data added successfully:', response.data);
        fetchLotteryData();
        setNumber('');
        setDrawNumber((parseInt(drawNumber) + 1).toString().padStart(7, '0'));
        setIsAddModalVisible(false);
        setPassword('');
        message.success('Data added successfully');
      } else {
        message.error('Error adding data: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error adding data:', error);
      message.error('Error adding data');
    }
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
    setPassword('');
  };

  const handleDelete = async (drawNumber) => {
    const endpoint = lotteryType === 'welfare' 
      ? `/api/admin/delete/${drawNumber}`
      : `/api/sport-lottery/${drawNumber}`;
    try {
      const response = await axios.delete(endpoint, { data: { password } });
      if (response.data.success) {
        console.log('Data deleted successfully:', response.data);
        fetchLotteryData();
        message.success('Data deleted successfully');
      } else {
        message.error('Error deleting data: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error deleting data:', error);
      message.error('Error deleting data');
    }
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999 && value.length <= 3)) {
      setNumber(value);
    }
  };

  const handleDrawNumberChange = (e) => {
    const value = e.target.value;
    if (value === '' || (value.length <= 7 && /^\d+$/.test(value))) {
      setDrawNumber(value);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleSearch = (value) => {
    setSearchDrawNumber(value);
    setCurrentPage(1);
  };

  const showDeleteConfirm = (drawNumber) => {
    setDeleteDrawNumber(drawNumber);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    handleDelete(deleteDrawNumber);
    setIsDeleteModalVisible(false);
    setPassword('');
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setPassword('');
  };

  const filteredData = lotteryData
    .filter(item => item.drawNumber.toString().includes(searchDrawNumber))
    .sort((a, b) => b.drawNumber - a.drawNumber);

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleLogin = async (values) => {
    try {
      const response = await axios.post('/api/login', values);
      if (response.data.success) {
        setIsLoggedIn(true);
        message.success('Login successful');
      } else {
        message.error('Incorrect username or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      message.error('Login failed');
    }
  };

  if (!isLoggedIn) {
    return (
      <div style={{ maxWidth: '300px', margin: '100px auto' }}>
        <h2>Admin Login</h2>
        <Form onFinish={handleLogin}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <div>
      <h2>Add Lottery Result</h2>
      <Select 
        value={lotteryType} 
        onChange={setLotteryType} 
        style={{ marginBottom: '20px' }}
      >
        <Option value="welfare">福利彩票3D</Option>
        <Option value="sport">体育彩票排列3</Option>
      </Select>
      <form onSubmit={showAddConfirm}>
        <div>
          <label>Number (000-999): </label>
          <input 
            type="text" 
            value={number} 
            onChange={handleNumberChange} 
            required 
            pattern="[0-9]{3}"
            title="Please enter a 3-digit number"
          />
        </div>
        <div>
          <label>Draw Number (7 digits): </label>
          <input 
            type="text" 
            value={drawNumber} 
            onChange={handleDrawNumberChange} 
            required 
            pattern="[0-9]{7}"
            title="Please enter a 7-digit number"
          />
        </div>
        <button type="submit">Add Result</button>
      </form>

      <h2>Lottery Results</h2>
      <Input.Search
        placeholder="Search by Draw Number"
        onSearch={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <table>
        <thead>
          <tr>
            <th>Draw Number</th>
            <th>Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(item => (
            <tr key={item.drawNumber}>
              <td>{item.drawNumber}</td>
              <td>{item.number}</td>
              <td>
                <button onClick={() => showDeleteConfirm(item.drawNumber)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        current={currentPage}
        total={filteredData.length}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: 16 }}
      />

      <Modal
        title="Confirm Add"
        visible={isAddModalVisible}
        onOk={handleAddConfirm}
        onCancel={handleAddCancel}
      >
        <p>Please enter the password to confirm addition:</p>
        <Input.Password
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>

      <Modal
        title="Confirm Delete"
        visible={isDeleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      >
        <p>Please enter the password to confirm deletion:</p>
        <Input.Password
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default Admin;