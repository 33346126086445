const limitDataPoints = (data, maxPoints) => {
  if (data.length <= maxPoints) return data;
  const step = Math.ceil(data.length / maxPoints);
  return data.filter((_, index) => index % step === 0);
};

export const getChartOptions = (visibleData) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title: function(tooltipItems) {
          if (tooltipItems.length > 0) {
            return ` ${tooltipItems[0].label}`;
          }
          return '';
        },
        label: function(context) {
          // 在打印时禁用工具提示
          if (window.matchMedia('print').matches) {
            return null;
          }

          const dataIndex = context.dataIndex;
          const dataPoint = visibleData[dataIndex];
          if (!dataPoint) return '';

          const kValue = dataPoint.endValue;
          const macdValue = context.chart.data.datasets.find(ds => ds.label === 'MACD直方图')?.data[dataIndex]?.y;

          if (context.datasetIndex === 0) {  // K线数据集
            return [
              `开奖号: ${dataPoint.number}`,
              `K值: ${kValue.toFixed(3)}`,
              `MA值: ${typeof macdValue === 'number' ? macdValue.toFixed(3) : 'N/A'}`
            ];
          }
          return null;  // 返回 null 以不显示其他数据集的 label
        }
      }
    },
    crosshair: {
      line: {
        color: '#FF0000',
        width: 1,
        dashPattern: [5, 5]
      },
      sync: {
        enabled: true,
        group: 1,
      },
      zoom: {
        enabled: false,
      },
      snap: {
        enabled: true,
      },
    }
  },
  layout: {
    padding: {
      top: 30,
      right: 20
    }
  },
  scales: {
    x: {
      type: 'category',
      reverse: true,
      ticks: {
        source: 'data',
        maxRotation: 0,
        autoSkip: true,
        maxTicksLimit: 20
      },
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: false,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)'
      },
      ticks: {
        callback: function(value) {
          return value.toFixed(2);
        }
      },
    },
    y2: {
      position: 'right',
      beginAtZero: true,
      grid: {
        drawOnChartArea: false,
      },
    }
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  animation: {
    duration: 0
  }
});

export const getChartData = (visibleData, showKLine, showKBar, showMA5, showMA10, showMA20, showMACD, showMACDFast, showMACDSlow, ma5, ma10, ma20, visibleMacdData) => {
  if (!Array.isArray(visibleData) || visibleData.length === 0) {
    console.warn('No valid visible data');
    return {
      labels: [],
      datasets: []
    };
  }

  const maxPoints = 2000;
  const limitedVisibleData = limitDataPoints(visibleData, maxPoints);

  // 添加安全检查
  if (!Array.isArray(limitedVisibleData) || limitedVisibleData.length === 0) {
    console.error('No valid visible data');
    return {
      labels: [],
      datasets: []
    };
  }

  const limitedMa5 = limitDataPoints(ma5, maxPoints);
  const limitedMa10 = limitDataPoints(ma10, maxPoints);
  const limitedMa20 = limitDataPoints(ma20, maxPoints);
  const limitedMacdData = {
    macd: limitDataPoints(visibleMacdData.macd, maxPoints),
    signal: limitDataPoints(visibleMacdData.signal, maxPoints),
    histogram: limitDataPoints(visibleMacdData.histogram, maxPoints)
  };

  return {
    labels: limitedVisibleData.map(item => item.drawNumber),
    datasets: [
      ...(showKBar ? [{
        label: 'K线',
        type: 'bar',
        data: limitedVisibleData.map((item) => ({
          x: item.drawNumber,
          y: [item.startValue, item.endValue],
          hasNumber: item.hasNumber
        })),
        backgroundColor: context => {
          const item = context.raw;
          if (!item || typeof item.hasNumber === 'undefined') return 'rgba(200, 200, 200, 0.5)';
          return item.hasNumber ? 'rgba(234, 67, 53, 0.8)' : 'rgba(66, 133, 244, 0.8)';
        },
        borderColor: context => {
          const item = context.raw;
          if (!item || typeof item.hasNumber === 'undefined') return 'rgba(200, 200, 200, 1)';
          return item.hasNumber ? 'rgba(234, 67, 53, 1)' : 'rgba(066, 133, 244, 1)';
        },
        borderWidth: 0.8,
        yAxisID: 'y'
      }] : []),
      ...(showKLine ? [{
        label: 'K值',
        type: 'line',
        data: limitedVisibleData.map(item => ({x: item.drawNumber, y: item.endValue})),
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        yAxisID: 'y',
        pointRadius: 0,
      }] : []),
      ...(showMA5 ? [{
        label: 'MA5',
        type: 'line',
        data: limitedMa5.map((value, index) => ({x: limitedVisibleData[index].drawNumber, y: value})),
        borderColor: '#4285F4',
        tension: 0.1,
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 1,
      }] : []),
      ...(showMA10 ? [{
        label: 'MA10',
        type: 'line',
        data: limitedMa10.map((value, index) => ({x: limitedVisibleData[index].drawNumber, y: value})),
        borderColor: '#FBBC05',
        tension: 0.1,
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 1,
      }] : []),
      ...(showMA20 ? [{
        label: 'MA20',
        type: 'line',
        data: limitedMa20.map((value, index) => ({x: limitedVisibleData[index].drawNumber, y: value})),
        borderColor: '#EA4335',
        tension: 0.1,
        yAxisID: 'y',
        pointRadius: 0,
        borderWidth: 1,
      }] : []),
      ...(showMACD ? [{
        label: 'MACD直方图',
        type: 'bar',
        data: limitedMacdData.histogram.map((value, index) => {
          const item = limitedVisibleData[index];
          return item ? {x: item.drawNumber, y: value} : null;
        }).filter(Boolean),
        backgroundColor: limitedMacdData.histogram.map(value => value >= 0 ? '#E06C6C' : '#A5D6B6'),
        yAxisID: 'y2',
      }] : []),
      ...(showMACDFast ? [{
        label: 'MACD快线',
        type: 'line',
        data: limitedMacdData.macd.map((value, index) => {
          const item = limitedVisibleData[index];
          return item ? {x: item.drawNumber, y: value} : null;
        }).filter(Boolean),
        borderColor: '#40CF66',
        tension: 0.1,
        yAxisID: 'y2',
        pointRadius: 0,
        borderWidth: 1,
      }] : []),
      ...(showMACDSlow ? [{
        label: 'MACD慢线',
        type: 'line',
        data: limitedMacdData.signal.map((value, index) => {
          const item = limitedVisibleData[index];
          return item ? {x: item.drawNumber, y: value} : null;
        }).filter(Boolean),
        borderColor: '#FF493A',
        tension: 0.1,
        yAxisID: 'y2',
        pointRadius: 0,
        borderWidth: 1,
      }] : []),
    ]
  };
};