import React from 'react';
import { Link } from 'react-router-dom';

export const ToggleButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    style={{
      backgroundColor: isActive ? '#4CAF50' : '#f1f1f1',
      color: isActive ? 'white' : 'black',
      border: 'none',
      padding: '10px 15px',
      margin: '5px',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: 'calc(0.8rem + 0.2vw)',
      whiteSpace: 'nowrap'
    }}
  >
    {children}
  </button>
);

export const PeriodButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    style={{
      backgroundColor: isActive ? '#4CAF50' : '#f1f1f1',
      color: isActive ? 'white' : 'black',
      border: 'none',
      padding: '10px 15px',
      margin: '5px',
      borderRadius: '4px',
      cursor: 'pointer'
    }}
  >
    {children}
  </button>
);

export const NumberButtons = ({ activeNumber, setActiveNumber, baseUrl = "/analysis" }) => (
  <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
      <Link key={n} to={`${baseUrl}/${n}`} style={{ margin: '5px' }} onClick={() => setActiveNumber(n.toString())}>
        <button style={{ 
          padding: '10px 15px', 
          backgroundColor: activeNumber === n.toString() ? 'red' : 'initial',
          color: activeNumber === n.toString() ? 'white' : 'initial',
          fontSize: 'calc(0.8rem + 0.5vw)',
          borderRadius: '4px',
          border: 'none',
          cursor: 'pointer'
        }}>
          胆{n}
        </button>
      </Link>
    ))}
  </div>
);